<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout
      justify-center
      wrap
      :style="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? 'margin-top:0px'
          : 'margin-top:70px'
      "
    >
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap justify-center>
          <v-flex xs12 pa-2>
            <v-spacer></v-spacer>
          </v-flex>

          <v-flex xs12 lg10 align-self-center pa-2 pb-8>
            <v-card>
              <v-form v-model="addslider" ref="addcat">
                <v-container>
                  <v-layout justify-center wrap>
                    <v-flex xs12 align-self-center text-center pa-3>
                      <span
                        style="font-size:24px;border-bottom:2px solid #FF6907"
                        class="BahnR"
                        >Internship Registration</span
                      >
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-text-field
                        class="homeText"
                        outlined
                        dense
                        v-model="name"
                        :rules="[rules.required]"
                        required
                        label="Full name"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-text-field
                        outlined
                        dense
                        maxlength="10"
                        minlength="10"
                        counter
                        :rules="phnoRules"
                        type="number"
                        v-model="phone"
                        class="homeText"
                        label="Phone"
                        hide-details
                        required
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-text-field
                        outlined
                        class="homeText"
                        dense
                        v-model="email"
                        :rules="emailRules"
                        label="Email"
                        hide-details
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-select
                        class="homeText"
                        :items="bacthlist"
                        label="Select Batch"
                        dense
                        item-text="name"
                        item-value="_id"
                        :rules="[rules.required]"
                        v-model="batch"
                        hide-details
                        outlined
                      ></v-select>
                    </v-flex>
                    <!-- <v-flex xs12 sm6 pa-2 align-self-center>
                        <v-select
                          class="homeText"
                          :items="projlist"
                          label="Select Project"
                          dense
                          item-text="name"
                          item-value="_id"
                          :rules="[rules.required]"
                          v-model="project"
                          hide-details
                          outlined
                        ></v-select>
                      </v-flex> -->

                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-select
                        class="homeText"
                        :items="typelist"
                        label="Select Technology type"
                        dense
                        v-model="type"
                        hide-details
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-text-field
                        class="homeText"
                        outlined
                        dense
                        v-model="department"
                        label="Department"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                      <v-text-field
                        class="homeText"
                        outlined
                        dense
                        v-model="collageBatch"
                        label="College Batch"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 pa-2 align-self-center>
                        <v-select
                          :items="numberOfMonthsList"
                          v-model="numberOfMonths"
                          item-text="name"
                          item-value="val"
                          label="Period of Internship in months"
                          hide-details
                          outlined
                          class="homeText"
                          dense
                        ></v-select>
                      </v-flex>
                    <!-- <v-flex xs12 sm6 pa-2 align-self-center>
                        <v-select
                          :items="internshipStatusList"
                          v-model="internshipStatus"
                          label="Internship Status"
                          hide-details
                          outlined
                          class="homeText"
                          dense
                        ></v-select>
                      </v-flex> -->

                    <!-- <v-flex xs12 pa-2 align-self-center>
                        <v-textarea
                          class="homeText"
                          outlined
                          dense
                          v-model="remark"
                          label=" Remarks"
                          hide-details
                        ></v-textarea>
                      </v-flex> -->
                    <v-flex xs12 align-self-center v-if="flagg" pa-2>
                      <ImageComp
                        @stepper="winStepper"
                        :height="'320'"
                        :width="'320'"
                        :heading="'Upload Profile Picture'"
                        :componentType="'coverImage'"
                      />
                    </v-flex>

                    <v-flex xs12>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs12 sm4 md3 align-self-center text-center pa-3>
                      <v-btn
                        color="#FF6907"
                        :disabled="!addslider"
                        block
                        class="rounded-xl"
                        @click="validateInput()"
                      >
                        <span>Submit</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../../store";

import ImageComp from "./singleImage";
import router from '../../../router';
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      list: [],
      editingitem: [],
      dialog: false,
      addslider: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
      bacthlist: [],
      batch: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      name: null,
      remark: null,
      rules: {
        required: (value) => !!value || "Required.",
      },

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
      typelist: ["Nodejs", "Vuejs", "Flutter"],
      type: null,
      projlist: [],
      project: null,
      email: "",
      phone: "",
      department: null,
      collageBatch: null,
      internshipStatus: null,
      internshipStatusList: ["On Progress", "Completed"],
      ftype: null,
      fbatch: null,
      fproject: null,
      currentPage: 1,
      pages: 0,
      pageCount: 1,
      flagg: true,
      numberOfMonths:null,
      numberOfMonthsList:[
        {
          name: "1 month",
          val: "1"
        },
        {
          name: "2 months",
          val: "2"
        },
        {
          name: "3 months",
          val: "3"
        },
        {
          name: "4 months",
          val: "4"
        },
        {
          name: "5 months",
          val: "5"
        },
        {
          name: "6 months",
          val: "6"
        },
      ],
      coverImageFile: null,
    };
  },
  beforeMount() {
    this.getBatch();
    // this.getProject();
  },
  mounted() {},
  methods: {
    winStepper(window_data) {
      if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    getBatch() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/internship/batch/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.bacthlist = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    // getProject() {
    //   axios({
    //     method: "GET",
    //     url: "/internship/project/getlist",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.projlist = response.data.data;
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },

    resetE() {
      this.$refs.addcat.resetValidation();
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateInput() {
      if (this.email) {
        if (!this.validateEmail(this.email)) {
          this.msg = "Please provide a valid email id";
          this.showSnackBar = true;
          return;
        }
      }

      if (!this.name) {
        this.msg = "Please provide student name";
        this.showSnackBar = true;
        return;
      } else if (!this.batch) {
        this.msg = "Please provide batch";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.numberOfMonths) {
        this.msg="Please provide period of internship"
        this.showSnackBar=true
        return
      }

      // else if (!this.project) {
      //   this.msg = "Please provide project";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    clearOff() {
      this.name = null;
      this.email = "";
      this.phone = "";
      this.type = null;
      this.department = null;
      this.collageBatch = null;
      this.internshipStatus = null;
      this.batch = null;
      this.project = null;
      this.remark = null;
      this.numberOfMonths=null
    },
    add() {
      if(this.email)
      this.email=this.email.trim()
      axios({
        method: "post",
        url: "/internship/candidate/register",
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          type: this.type,
          department: this.department,
          collageBatch: this.collageBatch,
          // internshipStatus: this.internshipStatus,
          batch: this.batch,
          numberOfMonths: this.numberOfMonths,
          // project: this.project,
          // remark: this.remark,
        },
        // headers: {
        //   "x-auth-token": localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            (this.dialog = false), (this.msg = " Added Successully");
            this.showSnackBar = true;

            if (this.coverImageFile) {
              this.uploadImage(response.data.id);
            } else {
              router.push('/Internship/login')
            }
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadImage(item) {
      this.appLoading = true;
      let formData = new FormData();
      formData.append("photo", this.coverImageFile);
      formData.append("id", item);
      axios({
        method: "POST",
        url: "/internship/candidate/myphoto",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data", 
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {

            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.imagePreview = null;
            this.dialog = false;
            this.editdialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
           router.push('/Internship/login')
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " "
      //  +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;
      return strTime;
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.texttt {
  line-height: 1.5em;
  min-height: 3.5em;
}
.showLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.v-application .primary {
  background-color: red !important;
}

.homeText {
  font-family: BahnR !important;
}
</style>
